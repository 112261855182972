<template>
  <page>
    <template #navigation>
      <div class="flex -mx-1">
        <action-icon
          class="mx-1"
          variant="primary"
          face="mdiPencilPlus"
          small
          v-if="isAdmin"
          @click="onEditItem"
        />
        <div class="flex-1 mx-1">
          <field
            compact
            type="search"
            :placeholder="$t('general.search')"
            small
            v-model="search"
          />
        </div>
      </div>
    </template>
    <grid
      name="types.list"
      :heading="procHeaders"
      :items="filteredItems"
      @clicked="onEditItem"
      :persist="persisting"
    />
    <modal
      v-if="currentItem"
      :title="
        currentItem._id === '#add'
          ? this.$t('actions.add')
          : this.$t('actions.edit')
      "
      @close="currentItem = null"
    >
      <form @submit.prevent="onSaveItem">
        <div v-for="item in editHeaders" :key="item.id">
          <dynamic-field
            :heading="item"
            :value="getCurrentItemValue(item)"
            @input="(event) => setCurrentItemValue(event, item)"
            @changed="clearCurrentError(item)"
            :error="currentItem.i18n(item.id)"
          />
        </div>
        <div class="flex justify-between items-center mt-1">
          <div class="flex items-center">
            <confirm
              v-if="currentItem._id !== '#add'"
              icons-only
              @expanded="onDeleteExpand"
              @click="onDelete"
            />
          </div>
          <div class="flex items-center space-x-1" v-show="!isDeleteExpanded">
            <action variant="link" @click="currentItem = null">{{
              $t('actions.cancel')
            }}</action>
            <action variant="primary" type="submit">{{
              $t('actions.save')
            }}</action>
          </div>
        </div>
      </form>
    </modal>
  </page>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Form, getProcHeaders, getEmptyHeaderValue } from '@/services'
import persistConfig from '@/services/grid/persistConfig'

export default {
  data() {
    return {
      search: '',
      currentItem: null,
      isDeleteExpanded: false
    }
  },
  mounted() {
    const config = persistConfig.load('types.list')
    this.search = config?.s ?? ''
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) =>
        state.settings.loading ||
        state.settings.updating ||
        state.headers.loading ||
        state.headers.updating,
      isAdmin: (state) => state.user?.role === 'ADMIN',
      settings: (state) => state.settings,
      headers: (state) => state.headers,
      types: (state) => state.types
    }),
    persisting() {
      return { s: this.search }
    },
    procHeaders() {
      if (!this.loaded) {
        return []
      }
      const _settings = this.settings.items.find(
        (item) => item.name === 'types'
      )
      const _headers = this.headers?.items.find((item) => item.name === 'types')
      return getProcHeaders(_settings.items, _headers?.items)
    },
    gridHeaders() {
      return this.procHeaders.filter((h) => !!h.show)
    },
    editHeaders() {
      return this.procHeaders.filter((h) => h.fill !== false)
    },
    filteredItems() {
      if (!this.types?.items) {
        return []
      }
      const _items = this.types.items.slice(0)
      const _search = this.search.trim().toLocaleLowerCase()
      if (!_search) {
        return _items
      }
      return _items.filter((item) => item.name.toLowerCase().includes(_search))
    }
  },
  methods: {
    ...mapActions(['types/create', 'types/update', 'types/destroy']),
    onEditItem(selected = null) {
      if (!this.isAdmin) {
        return false
      }
      const data = this.editHeaders.reduce(
        (item, h) => ({
          ...item,
          [h.id]: selected?.[h.id] || getEmptyHeaderValue(h)
        }),
        {}
      )
      data._id = selected?._id || '#add'

      const validation = this.editHeaders.reduce(
        (validation, h) => ({
          ...validation,
          ...(h.req || h.main ? { [h.id]: ['required'] } : {})
        }),
        {}
      )

      this.isDeleteExpanded = false

      this.currentItem = new Form(data, validation)
    },
    getCurrentItemValue(heading) {
      if (!this.currentItem) {
        return null
      }
      const v = this.currentItem[heading.id] || ''
      return JSON.parse(JSON.stringify(v))
    },
    setCurrentItemValue(value, heading) {
      if (!this.currentItem) {
        return null
      }
      this.$set(this.currentItem, heading.id, value)
    },
    clearCurrentError(heading) {
      if (!this.currentItem) {
        return null
      }
      this.currentItem.clear(heading.id)
    },
    async onSaveItem() {
      if (!this.currentItem) {
        return false
      }
      if (!this.isAdmin) {
        return false
      }

      if (!this.currentItem.validate()) {
        return false
      }

      const formData = this.currentItem.data

      const payload = this.editHeaders.reduce(
        (payload, h) => ({
          ...payload,
          [h.id]: formData[h.id]
        }),
        {}
      )

      if (this.currentItem._id === '#add') {
        await this['types/create'](payload)
      } else {
        await this['types/update']({ _id: this.currentItem._id, ...payload })
      }

      this.currentItem = null
    },
    onDeleteExpand(expanded) {
      if (!this.currentItem) {
        return
      }
      this.isDeleteExpanded = expanded
    },
    async onDelete() {
      if (!this.currentItem) {
        return null
      }
      await this['types/destroy'](this.currentItem)
      this.currentItem = null
    }
  }
}
</script>
